// jQuery start
jQuery(function ($) {

  // SP 100vh(スクロールバー対策)
  // $('.loadingLogo').css('height',$(window).height());


  // Smooth scroll
  // ページ内リンク
  var width = $(window).width();
  if (width <= 1023) {
    var headerHight = 60; //ヘッダの高さ
  } else {
    var headerHight = 100; //ヘッダの高さ
  }

  $('a[href^="#"]').click(function () {
    var speed = 800;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - headerHight;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });

  // ページ外リンク
  $(window).on('load', function () {
    if (document.URL.match("#")) {
      var str = location.href;
      var cut_str = "#";
      var index = str.indexOf(cut_str);
      var href = str.slice(index);
      var target = href;
      var position = $(target).offset().top - headerHight;
      $("html, body").scrollTop(position);
      return false;
    }
  });

  // loading
  $(document).ready(function () {
    jQuery(".loading").addClass("active");
  });

  // $(window).on('load', function () {
  //   jQuery(".loading").addClass("active");
  // });

  // ギャラリースライダー部分
  $('.slider').slick({
	    autoplay: true,         //自動再生
	    autoplaySpeed: 3200,　  //自動再生のスピード
	    speed: 2000,		　 //スライドするスピード
	    dots: false,　　　　　　//スライドしたのドット
	    arrows: false,          //左右の矢印
	    infinite: true,　　　　//スライドのループ
	    pauseOnHover: false,   //ホバーしたときにスライドを一時停止しない
      fade: true,
	});


  // scroll class add
  jQuery(window).on("load scroll resize", function () {

    var st = jQuery(window).scrollTop();
    var wh = jQuery(window).height();
    var ww = jQuery(window).width();

    // fast(<1) slow(>1)
    jQuery(".fade, .fadeMask, .fadeUp, .fadeIn, fadeAnimation").each(function (i) {
      var tg = jQuery(this).offset().top - wh * 0.9;
      if (st > tg) {
        jQuery(this).addClass("active");
      }
    });

    jQuery(".fade-up-fast").each(function (i) {
      var tg = jQuery(this).offset().top - wh * 0.8;
      if (st > tg) {
        jQuery(this).addClass("active");
      }
    });

  }); // scroll class add END


  // Click event humberger menu
  jQuery('.navBar').click(function () {
    jQuery('.l-header').toggleClass('active');
    jQuery('.gnav').toggleClass('active');
    jQuery('.header__inner').toggleClass('active');
    jQuery('.navBar__line').toggleClass('active');
    jQuery('.header__body').toggleClass('active');
  });

  // Procuct List Toggle
  jQuery('.product__ttl').click(function () {
    jQuery(this).delay(5000).toggleClass('open');
    jQuery(this).next('.product__answer').slideToggle();
  });
  jQuery('.stockList__shop').click(function () {
    jQuery(this).delay(5000).toggleClass('open');
    jQuery(this).next('.stockList__info').slideToggle();
  });
  
  $(function() {
    if (!isPhone())
        return;

    $('span[data-action=call]').each(function() {
        var $ele = $(this);
        $ele.wrap('<a href="tel:' + $ele.data('tel') + '"></a>');
    });
});

function isPhone() {
    return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0);
}


});


// jQuery End

