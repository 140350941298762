// -------------------------------------------------------------------
// common：電話番号リンクの制御(PCの場合はtelリンクを無効化)
// jsTelLinkクラスを持つ、リンクタグ(aタグ)のみに有効
// -------------------------------------------------------------------
$(document).ready(function(){
	//判定モード
	//・ユーザーエージェント判定：ua
	//・画面幅判定：width(ua以外なら何でも)
	var strMode = 'ua';


	var switchTelLink = function(){
		var bDisable = true;
		switch(strMode){
			//ユーザーエージェント判定
			case 'ua': {
				var ua = navigator.userAgent.toLowerCase();

				//どれにも当てはまらなければ、PC(リンク無効)
				bDisable = (ua.indexOf('phone') == -1 &&//iPhoneとWindows phone
							ua.indexOf('ipad') == -1 &&//iPad
							ua.indexOf('android') == -1); //AndroidとAndroidタブレット

				break;
			}

			//画面幅判定
			default: {
				bDisable = ($(window).width() > SP_MAX_WIDTH);
				break;
			}
		}

		//リンクを無効にする
		if(bDisable){
			$('a.jsTelLink').each(function(){
				var elmNew = $('<span/>', {
					'class': $(this).attr('class')
				});
				elmNew.html($(this).html());

				$(this).replaceWith(elmNew);
			});
		}
		//リンクを有効にする
		else{
			$('span.jsTelLink').each(function(){
				var elmNew = $('<a/>', {
					'class': $(this).attr('class')
				});
				elmNew.html($(this).html());

				$(this).replaceWith(elmNew);
			});
		}
	};
	switchTelLink();

	//リサイズ時に実行
	if(strMode != 'ua'){
		$(window).on('resize', switchTelLink);
	}
});
